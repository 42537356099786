<template>
  <div>
    <div class="table_wrap">
      <table border="0" cellpadding="0" cellspacing="0" class="vertical-center">
        <tr>
          <td rowspan="4">
            FREQ.
          </td>
          <td colspan="2" style="text-align: left;">
            1st Art.
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td width="10">
            Clover
          </td>
          <td width="10">
            Result
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: left;">
            Art. 2-20
          </td>
          <td colspan="2" rowspan="2">
            Before &amp; after
            <br>
            tool change
          </td>
          <td>100%</td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>20%</td>
          <td>If app</td>
          <td />
        </tr>
        <tr>
          <td colspan="2" style="text-align: left;">
            Art. 21+
          </td>
          <td>100%</td>
          <td>20%</td>
          <td>20%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            20%
          </td>
          <td colspan="2">
            20%
          </td>
          <td>20%</td>
          <td colspan="2">
            20%
          </td>
          <td>20%</td>
          <td colspan="2">
            20%
          </td>
          <td>20%</td>
          <td>20%</td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="2" style="text-align: left;">
            Last Art.
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td />
          <td />
        </tr>
        <tr class="solid-top">
          <td colspan="3" rowspan="2" />
          <td colspan="2">
            <strong>
              (A)
              Overlay Insp.
            </strong>
          </td>
          <td rowspan="2" width="10">
            <strong>
              (B)
              <br>
              Visual
              <br>
              Insp.
            </strong>
          </td>
          <td rowspan="2" width="10">
            <strong>
              (C)
              <br>
              Thread
              <br>
              Profile
            </strong>
          </td>
          <td rowspan="2">
            <strong>(D)<br>MLFC</strong>
          </td>
          <td colspan="3">
            <strong>(E) Thread</strong>
          </td>
          <td colspan="3">
            <strong>(F) Seal</strong>
          </td>
          <td colspan="2">
            <strong>(G) Lead</strong>
          </td>
          <td colspan="2">
            <strong>(H) Taper</strong>
          </td>
          <td rowspan="2">
            <strong>
              (I)
              <br>
              Thread Height
            </strong>
          </td>
          <td colspan="2" rowspan="2">
            <strong>(J)
              <br>Nose O.D.
            </strong>
          </td>
          <td rowspan="2">
            <strong>(K)<br>Nose Length</strong>
          </td>
          <td colspan="2" rowspan="2">
            <strong>(L)<br>Bore I.D.</strong>
          </td>
          <td rowspan="2">
            <strong>(M)<br>Bore<br>Length</strong>
          </td>
          <td rowspan="2">
            <strong>
              (N)
              <br>
              RIRO
            </strong>
          </td>
          <td rowspan="2">
            <strong>
              O:Acc
              <br>
              X:Rej
            </strong>
          </td>
          <td rowspan="2">
            <strong>
              O:Acc
              <br>
              X:Rej
            </strong>
          </td>
        </tr>
        <tr>
          <td width="10">
            <strong>
              Thread
              <br>
              Form
            </strong>
          </td>
          <td width="10">
            <strong>
              Seal
              <br>
              Form
            </strong>
          </td>
          <td colspan="2">
            <strong>Thread Dia.</strong>
          </td>
          <td><strong>Ovality</strong></td>
          <td colspan="2">
            <strong>Seal Dia.</strong>
          </td>
          <td><strong>Ovality</strong></td>
          <td><strong>Lead</strong></td>
          <td>
            <strong>
              Cum. Lead
            </strong>
          </td>
          <td><strong>Perf.</strong></td>
          <td><strong>Imp.</strong></td>
        </tr>
        <tr>
          <td colspan="3">
            Upper Tolerance
          </td>
          <td rowspan="4" />
          <td rowspan="4" />
          <td rowspan="4" />
          <td rowspan="4" />
          <td>-</td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('ThreadDiaUt')">
              <span v-show="getInspectionSheetDataForWeight('ThreadDiaUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('ThreadDiaUt')" />
            </span>
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('ThreadDiaOvality')" /></td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('SealDiaUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('SealDiaUt')" />
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('SealDiaOvality')" /></td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('LeadUt')">
              <span v-show="getInspectionSheetDataForWeight('LeadUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('LeadUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('CumLeadUt')">
              <span v-show="getInspectionSheetDataForWeight('CumLeadUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('CumLeadUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('TaperPUt')">
              <span v-show="getInspectionSheetDataForWeight('TaperPUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('TaperPUt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('TaperIUt')">
              <span v-show="getInspectionSheetDataForWeight('TaperIUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('TaperIUt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('ThreadHtUt')">
              <span v-show="getInspectionSheetDataForWeight('ThreadHtUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('ThreadHtUt')" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('NoseOdUt')">
              <span v-show="getInspectionSheetDataForWeight('NoseOdUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('NoseOdUt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('NoseLenUt')">
              <span v-show="getInspectionSheetDataForWeight('NoseLenUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('NoseLenUt', 3)" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('BoreIdUt')">
              <span v-show="getInspectionSheetDataForWeight('BoreIdUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('BoreIdUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('BoreLenUt')">
              <span v-show="getInspectionSheetDataForWeight('BoreLenUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('BoreLenUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('RuninRunoutUt')">
              <span v-show="getInspectionSheetDataForWeight('RuninRunoutUt', 4, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('RuninRunoutUt', 4)" />
            </span>
          </td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="3">
            Nominal
          </td>
          <td>-</td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('ThreadDiaNom')" />
          </td>
          <td>-</td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('SealDiaNom')" />
          </td>
          <td>-</td>
          <td><span v-html="getInspectionSheetDataForWeight('LeadNom', 0)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('CumLeadNom', 0)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('TaperPNom')" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('TaperINom')" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('ThreadHtNom')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('NoseOdNom')" />
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('NoseLenNom', 3)" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('BoreIdNom', 3)" />
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('BoreLenNom', 3)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('RuninRunoutNom', 4)" /></td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="3">
            Lower Tolerance
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('MinLfc')" /></td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('ThreadDiaLt')">
              <span v-if="getInspectionSheetDataForWeight('ThreadDiaLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('ThreadDiaLt')" />
            </span>
          </td>
          <td />
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('SealDiaLt')">
              <span v-if="getInspectionSheetDataForWeight('SealDiaLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('SealDiaLt')" />
            </span>
          </td>
          <td>-</td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('LeadLt')">
              <span v-if="getInspectionSheetDataForWeight('LeadLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('LeadLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('CumLeadLt')">
              <span v-if="getInspectionSheetDataForWeight('CumLeadLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('CumLeadLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('TaperPLt')">
              <span v-if="getInspectionSheetDataForWeight('TaperPLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('TaperPLt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('TaperILt')">
              <span v-if="getInspectionSheetDataForWeight('TaperILt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('TaperILt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('ThreadHtLt')">
              <span v-if="getInspectionSheetDataForWeight('ThreadHtLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('ThreadHtLt')" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('NoseOdLt')">
              <span v-if="getInspectionSheetDataForWeight('NoseOdLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('NoseOdLt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('NoseLenLt')">
              <span v-if="getInspectionSheetDataForWeight('NoseLenLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('NoseLenLt', 3)" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('BoreIdLt')">
              <span v-if="getInspectionSheetDataForWeight('BoreIdLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('BoreIdLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('BoreLenLt')">
              <span v-if="getInspectionSheetDataForWeight('BoreLenLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('BoreLenLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('RuninRunoutLt')">
              <span v-if="getInspectionSheetDataForWeight('RuninRunoutLt', 4, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('RuninRunoutLt', 4)" />
            </span>
          </td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="3">
            Range
          </td>
          <td><span class="sc">&gt;</span> <span v-html="getInspectionSheetDataForWeight('MinLfc')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('ThreadDiaRa', 4)" />
          </td>
          <td><span class="sc">&lt;</span> <span v-html="getInspectionSheetDataForWeight('ThreadDiaOvality')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('SealDiaRa', 4)" />
          </td>
          <td><span class="sc">&lt;</span> <span v-html="getInspectionSheetDataForWeight('SealDiaOvality')" /></td>
          <td colspan="2">
            -
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('TaperPRa')" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('TaperIRa')" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('ThreadHtRa')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('NoseOdRa')" />
          </td>
          <td>
            <span v-html="getInspectionSheetDataForWeight('NoseLenRa', 3)" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('BoreIdRa', 3)" />
          </td>
          <td>
            <span v-if="getInspectionSheetDataForWeight('BoreLenUt', 3, false) > 0 && getInspectionSheetDataForWeight('BoreLenLt', 3, false)" v-html="getInspectionSheetDataForWeight('BoreLenRa', 3)" />
            <span v-else>
              <span class="sc">&gt;</span> <span v-html="getInspectionSheetDataForWeight('BoreLenNom', 3)" />
            </span>
          </td>
          <td>
            <span v-if="getInspectionSheetDataForWeight('RuninRunoutUt', 4, false) && getInspectionSheetDataForWeight('RuninRunoutNom', 4, false) && getInspectionSheetDataForWeight('RuninRunoutLt', 4, false)" v-html="getInspectionSheetDataForWeight('RuninRunoutRa', 4)" />
            <span v-else>
              <span class="sc">&lt;</span> <span v-html="getInspectionSheetDataForWeight('RuninRunoutUt', 4)" />
            </span>
          </td>
          <td />
          <td />
        </tr>
        <tr class="solid-top no-bottom-border">
          <td colspan="3">
            Gauge
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('p_ga_overlay', false)" />
          </td>
          <td />
          <td><span v-html="getInspectionSheetDataText('p_ga_thr_pro', false)" /></td>
          <td>Prec. Scale</td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataText('p_ga_thread_dia', false)" />
          </td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataText('p_ga_seal', false)" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('p_ga_lead', false)" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('p_ga_taper', false)" />
          </td>
          <td><span v-html="getInspectionSheetDataText('p_ga_thr_ht', false)" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('p_ga_nose_od', false)" />
          </td>
          <td><span v-html="getInspectionSheetDataText('p_ga_nose_len', false)" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('p_ga_bore_id', false)" />
          </td>
          <td><span v-html="getInspectionSheetDataText('p_ga_bore_len', false)" /></td>
          <td />
          <td />
          <td />
        </tr>
        <tr class="dashed-border-top">
          <td colspan="3" class="nowrap wide">
            <span>Contact Points Dia. &#8594;</span>
          </td>
          <td colspan="2">
            -
          </td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td colspan="3">
            TPI <span v-html="getInspectionSheetDataForWeight('Tpi', false)" />
          </td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataText('b_doc')" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('Cplt', 3)" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('Cplt', 3)" />
          </td>
          <td>-</td>
          <td colspan="2">
            -
          </td>
          <td>-</td>
          <td colspan="2">
            -
          </td>
          <td>-</td>
          <td />
          <td />
          <td />
        </tr>

        <template v-for="n in 5">
          <tr :key="n.index" class="stroke-top">
            <td colspan="2" rowspan="4" align="left">
              Joint No.
            </td>
            <td rowspan="2">
              side A
            </td>
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td rowspan="2" class="fillable" />
            <td />
            <td />
            <td />
          </tr>
          <tr :key="n.index">
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="dotty-top">
              <sup>2nd</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td />
            <td />
            <td />
          </tr>
          <tr :key="n.index" class="stroke-top">
            <td rowspan="2">
              side B
            </td>
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td rowspan="2" class="fillable" />
            <td />
            <td />
            <td />
          </tr>
          <tr :key="n.index">
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="dotty-top">
              <sup>2nd</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td />
            <td />
            <td />
          </tr>
        </template>
      </table>
    </div>

    <div class="table_wrap">
      <table border="0" cellpadding="0" cellspacing="0">
        <tr>
          <td width="12%">
            Sub Scale:
          </td>
          <td width="12%">
            Thread:
          </td>
          <td width="12%">
            Seal:
          </td>
          <td width="40%">
            <span v-html="getInspectionSheetDataForWeight('Footer1', 0, false)" />
          </td>
          <td width="12%">
            <table cellspacing="0" cellpadding="0" style="border: 0px solid white;">
              <tr>
                <td style="border: 0px solid white;">
                  <strong>SIZE</strong>
                </td>
                <td style="border: 0px solid white;">
                  <strong>L</strong>
                </td>
              </tr>
              <tr>
                <td style="border: 0px solid white;">
                  {{ size.size }}&quot;
                </td>
                <td style="border: 0px solid white;">
                  <span v-html="getInspectionSheetDataForWeight('Trpd', 3)" />
                </td>
              </tr>
            </table>
          </td>
          <td valign="middle" width="90">
            <img src="@/assets/images/gauge_sheet/L-diagram-pin.png" alt="pin diagram" width="89" height="51" style="margin-top: 2px">
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PinTemplate',
  props: {
    connection: {
      required: true,
      type: Object
    },
    applicationSelect: {
      type: String,
      default: 'box'
    },
    size: {
      required: true,
      type: Object
    },
    weight: {
      required: true,
      type: Object
    },
    measure: {
      type: String,
      required: true
    },
    gaugeSheetText: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      decimalMetric: 2
    }
  },
  computed: {
    uom () {
      if (this.measure === 'imp') {
        return '"'
      }
      return '<small>mm</small>'
    }
  },
  async mounted () {
    this.loaded = true
  },
  methods: {
    getInspectionSheetDataForWeight (field, decimal = 4, sign = true) {
      try {
        const composedField = 'inspPin' + field
        const raField = composedField.substring(0, composedField.length - 2)

        switch (field) {
          case 'ThreadDiaRa':
          case 'TaperPRa':
          case 'TaperIRa':
          case 'SealDiaRa':
          case 'ThreadHtRa':
          case 'NoseOdRa':
          case 'NoseLenRa':
          case 'BoreLenRa':
          case 'BoreIdRa':
          case 'RuninRunoutRa':
            let ltRange = 0
            let utRange = 0

            if (this.measure === 'imp') {
              ltRange = Math.abs(parseFloat(this.weight[raField + 'Nom'] || 0) + parseFloat(this.weight[raField + 'Lt'] || 0))
              utRange = Math.abs(parseFloat(this.weight[raField + 'Nom'] || 0) + parseFloat(this.weight[raField + 'Ut'] || 0))
              return (this.numberFormat(ltRange, decimal) || '') + ' - ' + (this.numberFormat(utRange, decimal) || '') + ((sign && ltRange && utRange) ? this.uom : '')
            } else {
              ltRange = Math.abs(parseFloat(this.numberFormat((this.weight[raField + 'Nom'] * 25.4), this.decimalMetric) || 0) + parseFloat(this.numberFormat((this.weight[raField + 'Lt'] * 25.4), this.decimalMetric) || 0))
              utRange = Math.abs(parseFloat(this.numberFormat((this.weight[raField + 'Nom'] * 25.4), this.decimalMetric) || 0) + parseFloat(this.numberFormat((this.weight[raField + 'Ut'] * 25.4), this.decimalMetric) || 0))
              return (this.numberFormat(ltRange) || '') + ' - ' + (this.numberFormat(utRange) || '') + ((sign && ltRange && utRange) ? this.uom : '')
            }

          case 'Footer1':
            return this.weight[composedField]

          default:
            const fieldValue = this.weight[composedField]

            if (!fieldValue && fieldValue !== 0) {
              return null
            }

            if (this.measure === 'met' && !isNaN(fieldValue)) {
              return this.numberFormat(fieldValue * 25.4, 2, true) + ((sign) ? this.uom : '')
            }

            return this.numberFormat(fieldValue, decimal, true) + ((sign) ? this.uom : '')
        }
      } catch (e) {
        // Not found
        return e
      }
    },
    getInspectionSheetDataText (field, sign = true) {
      try {
        switch (field) {
          default:
            const fieldValue = this.getInspectionSheetDBValue(field)

            if (!fieldValue) {
              return '-'
            }

            if (this.measure === 'met' && !isNaN(fieldValue.replace)) {
              return this.numberFormat(fieldValue.replace * 25.4, 2) + ((sign) ? this.uom : '')
            }
            return fieldValue.replace + ((sign) ? this.uom : '')
        }
      } catch (e) {
        // Not found
        return e
      }
    },
    getInspectionSheetDBValue (field) {
      return this.gaugeSheetText.find(t => t.field === field && t.connectionId === this.connection.id)
    }
  }
}
</script>
