<template>
  <div>
    <div class="table_wrap">
      <table border="0" cellpadding="0" cellspacing="0" class="vertical-center">
        <tr>
          <td rowspan="4">
            FREQ.
          </td>
          <td colspan="2" style="text-align: left;">
            1st Art.
          </td>
          <td colspan="2">
            100%
          </td>
          <td width="10">
            100%
          </td>
          <td width="10">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td>100%</td>
          <td width="10">
            Clover
          </td>
          <td width="10">
            Result
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align: left;">
            Art. 2-20
          </td>
          <td colspan="2" rowspan="2">
            Before &amp; after tool change
          </td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td>100%</td>
          <td>If app</td>
          <td />
        </tr>
        <tr>
          <td colspan="2" style="text-align: left;">
            Art. 21+
          </td>
          <td>100%</td>
          <td>20%</td>
          <td colspan="2">
            20%
          </td>
          <td>20%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            20%
          </td>
          <td>20%</td>
          <td>20%</td>
          <td colspan="2">
            20%
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            20%
          </td>
          <td>20%</td>
          <td>20%</td>
          <td>20%</td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="2" style="text-align: left;">
            Last Art.
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td colspan="3">
            100%
          </td>
          <td colspan="3">
            100%
          </td>
          <td colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td colspan="2">
            100%
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            100%
          </td>
          <td>100%</td>
          <td>100%</td>
          <td>100%</td>
          <td />
          <td />
        </tr>
        <tr class="solid-top">
          <td colspan="3" rowspan="2" />
          <td colspan="2">
            <strong>
              (A)
              Overlay Insp.
            </strong>
          </td>
          <td rowspan="2">
            <strong>
              (B) Visual Insp.
            </strong>
          </td>
          <td rowspan="2">
            <strong>
              (C) Thread Profile
            </strong>
          </td>
          <td colspan="2">
            <strong>(D)</strong>
          </td>
          <td rowspan="2">
            <strong>
              (E) Should. Depth
            </strong>
          </td>
          <td colspan="3">
            <strong>(F) Thread</strong>
          </td>
          <td colspan="3">
            <strong>(G) Seal</strong>
          </td>
          <td colspan="2">
            <strong>(H) Lead</strong>
          </td>
          <td rowspan="2" class="semiwide">
            <strong>(I)<br>Taper Perfect</strong>
          </td>
          <td rowspan="2" class="semiwide">
            <strong>
              (J)<br>Thread Height
            </strong>
          </td>
          <td colspan="2" rowspan="2">
            <strong>(K)<br>Bore I.D.</strong>
          </td>
          <td v-show="!lionOrTiger" colspan="2" rowspan="2">
            <strong>(L)<br>Nose I.D.</strong>
          </td>
          <td rowspan="2">
            <strong>
              <span v-if="!lionOrTiger">(M)</span>
              <span v-else>(L)</span>
              <br>Coupl. O.D.</strong>
          </td>
          <td rowspan="2">
            <strong>
              <span v-if="!lionOrTiger">(N)</span>
              <span v-else>(M)</span>
              Coupl. Length</strong>
          </td>
          <td rowspan="2">
            <strong>
              (0) MBFW
            </strong>
          </td>
          <td rowspan="2">
            <strong>
              O:Acc<br>X:Rej
            </strong>
          </td>
          <td rowspan="2">
            <strong>
              O:Acc<br>X:Rej
            </strong>
          </td>
        </tr>
        <tr>
          <td width="10">
            <strong>
              Thread Form
            </strong>
          </td>
          <td width="10">
            <strong>
              Seal Form
            </strong>
          </td>
          <td class="semiwide">
            <strong>MPTL</strong>
          </td>
          <td class="semiwide">
            <strong>MTRO</strong>
          </td>
          <td colspan="2">
            <strong>Thread Dia.</strong>
          </td>
          <td><strong>Ovality</strong></td>
          <td colspan="2">
            <strong>Seal Dia.</strong>
          </td>
          <td><strong>Ovality</strong></td>
          <td><strong>Lead</strong></td>
          <td>
            <strong>
              Cum. Lead
            </strong>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            Upper Tolerance
          </td>
          <td rowspan="4" />
          <td rowspan="4" />
          <td rowspan="4" />
          <td rowspan="4" />
          <td>-</td>
          <td><span v-html="getInspectionSheetDataForWeight('inspMaxTro', 3)" /></td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspShoulDepUt')">
              <span v-show="getInspectionSheetDataForWeight('inspShoulDepUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspShoulDepUt', 3)" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxThreadDiaUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxThreadDiaUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxThreadDiaUt')" />
            </span>
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxThreadDiaOvality')" /></td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxSealDiaUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxSealDiaUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxSealDiaUt')" />
            </span>
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxSealDiaOvality')" /></td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxLeadUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxLeadUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxLeadUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxCumLeadUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxCumLeadUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxCumLeadUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxTaperPUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxTaperPUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxTaperPUt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxThreadHtUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxThreadHtUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxThreadHtUt')" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxBoreIdUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxBoreIdUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxBoreIdUt', 3)" />
            </span>
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxNoseIdUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxNoseIdUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxNoseIdUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspCoupOdUt')">
              <span v-show="getInspectionSheetDataForWeight('inspCoupOdUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspCoupOdUt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxCoupLenUt')">
              <span v-show="getInspectionSheetDataForWeight('inspBoxCoupLenUt', 3, false) >= 0" class="sc">+</span> <span v-html="getInspectionSheetDataForWeight('inspBoxCoupLenUt', 3)" />
            </span>
          </td>
          <td>-</td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="3">
            Nominal
          </td>
          <td>-</td>
          <td>-</td>
          <td><span v-html="getInspectionSheetDataForWeight('inspShoulDepNom', 3)" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxThreadDiaNom')" />
          </td>
          <td>-</td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxSealDiaNom')" />
          </td>
          <td>-</td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxLeadNom', 0)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxCumLeadNom', 0)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxTaperPNom')" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxThreadHtNom')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxBoreIdNom', 3)" />
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxNoseIdNom', 3)" />
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspCoupOdNom', 3)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxCoupLenNom', 3)" /></td>
          <td>-</td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="3">
            Lower Tolerance
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspMinPtl', 3)" /></td>
          <td>-</td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspShoulDepLt')">
              <span v-if="getInspectionSheetDataForWeight('inspShoulDepLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspShoulDepLt', 3)" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxThreadDiaLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxThreadDiaLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxThreadDiaLt')" />
            </span>
          </td>
          <td>-</td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxSealDiaLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxSealDiaLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxSealDiaLt')" />
            </span>
          </td>
          <td>-</td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxLeadLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxLeadLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxLeadLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxCumLeadLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxCumLeadLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxCumLeadLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxTaperPLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxTaperPLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxTaperPLt')" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxThreadHtLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxThreadHtLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxThreadHtLt')" />
            </span>
          </td>
          <td colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxBoreIdLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxBoreIdLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxBoreIdLt', 3)" />
            </span>
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            <span v-show="getInspectionSheetDataForWeight('inspBoxNoseIdLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxBoreIdLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxNoseIdLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspCoupOdLt')">
              <span v-if="getInspectionSheetDataForWeight('inspCoupOdLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspCoupOdLt', 3)" />
            </span>
          </td>
          <td>
            <span v-show="getInspectionSheetDataForWeight('inspBoxCoupLenLt')">
              <span v-if="getInspectionSheetDataForWeight('inspBoxCoupLenLt', 3, false) >= 0" class="sc">+</span><span v-html="getInspectionSheetDataForWeight('inspBoxCoupLenLt', 3)" />
            </span>
          </td>
          <td>
            <span v-if="bevel && getInspectionSheetDataForWeight('inspCoupMbFw', 3)" v-html="getInspectionSheetDataForWeight('inspCoupMbFw', 3)" />
            <span v-else>-</span>
          </td>
          <td />
          <td />
        </tr>
        <tr>
          <td colspan="3">
            Range
          </td>
          <td><span class="sc">&gt;</span> <span v-html="getInspectionSheetDataForWeight('inspMinPtl', 3)" /></td>
          <td><span class="sc">&lt;</span> <span v-html="getInspectionSheetDataForWeight('inspMaxTro', 3)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspShoulDepRa', 3)" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxThreadDiaRa')" />
          </td>
          <td><span class="sc">&lt;</span> <span v-html="getInspectionSheetDataForWeight('inspBoxThreadDiaOvality')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxSealDiaRa')" />
          </td>
          <td><span class="sc">&lt;</span> <span v-html="getInspectionSheetDataForWeight('inspBoxSealDiaOvality')" /></td>
          <td colspan="2">
            -
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxTaperPRa')" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxThreadHtRa')" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxBoreIdRa', 3)" />
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxNoseIdRa', 3)" />
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspCoupOdRa', 3)" /></td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxCoupLenRa', 3)" /></td>
          <td>
            <span v-if="bevel && getInspectionSheetDataForWeight('inspCoupMbFw', 3)" class="sc">&ge;&nbsp;<span v-html="getInspectionSheetDataForWeight('inspCoupMbFw', 3)" /></span>
            <span v-else>-</span>
          </td>
          <td />
          <td />
        </tr>
        <tr class="solid-top no-bottom-border">
          <td colspan="3">
            Gauge
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('b_ga_overlay', false)" />
          </td>
          <td />
          <td><span v-html="getInspectionSheetDataText('b_ga_thr_pro', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_mptl', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_mtro', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_should_dep', false)" /></td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataText('b_ga_thread_dia', false)" />
          </td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataText('b_ga_seal', false)" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('b_ga_lead', false)" />
          </td>
          <td><span v-html="getInspectionSheetDataText('b_ga_taper', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_thr_ht', false)" /></td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataText('b_ga_bore_id', false)" />
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            <span v-html="getInspectionSheetDataText('b_ga_nose_id', false)" />
          </td>
          <td><span v-html="getInspectionSheetDataText('b_ga_coup_od', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_coup_len', false)" /></td>
          <td>Caliper</td>
          <td />
          <td />
        </tr>
        <tr class="dashed-border-top">
          <td colspan="3" class="nowrap wide">
            <span>Contact Points Dia. &#8594;</span>
          </td>
          <td colspan="2">
            -
          </td>
          <td>-</td>
          <td>-</td>
          <td><span v-html="getInspectionSheetDataText('b_ga_cpmptl', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_cpmtro', false)" /></td>
          <td><span v-html="getInspectionSheetDataText('b_ga_cpshould_dep', false)" /></td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataForWeight('inspBoxShoesLength', 3)" /> shoes
          </td>
          <td colspan="3">
            <span v-html="getInspectionSheetDataForWeight('inspBoxCpSealDia', 3)" />
          </td>
          <td colspan="2">
            <span v-html="getInspectionSheetDataForWeight('inspBoxCpLeadTaper', 3)" />
          </td>
          <td><span v-html="getInspectionSheetDataForWeight('inspBoxCpLeadTaper', 3)" /></td>
          <td>-</td>
          <td colspan="2">
            -
          </td>
          <td v-show="!lionOrTiger" colspan="2">
            -
          </td>
          <td>-</td>
          <td>-</td>
          <td />
          <td />
          <td />
        </tr>

        <template v-for="n in 5">
          <tr :key="n.index" class="stroke-top">
            <td colspan="2" rowspan="4" align="left">
              Coupling No.
            </td>
            <td rowspan="2">
              side A
            </td>
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td colspan="2" rowspan="2" class="slash-cell" /><td v-show="!lionOrTiger" class="fillable">
              <sup>Hi</sup>
            </td>
            <td v-show="!lionOrTiger" class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="4" /><td rowspan="4" />
            <td />
            <td />
            <td />
          </tr>
          <tr :key="n.index">
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td v-show="!lionOrTiger" colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td />
            <td />
            <td />
          </tr>
          <tr :key="n.index" class="stroke-top">
            <td rowspan="2">
              side B
            </td>
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td rowspan="2" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td rowspan="2" class="fillable" />
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td class="fillable">
              <sup>1st</sup>
            </td>
            <td class="fillable">
              <sup>Hi</sup>
            </td>
            <td class="fillable">
              <sup>Lo</sup>
            </td>
            <td v-show="!lionOrTiger" class="fillable">
              <sup>Hi</sup>
            </td>
            <td v-show="!lionOrTiger" class="fillable">
              <sup>Lo</sup>
            </td>
            <td />
            <td />
            <td />
          </tr>
          <tr :key="n.index">
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td class="fillable">
              <sup>2nd</sup>
            </td>
            <td colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td v-show="!lionOrTiger" colspan="2" class="fillable">
              <sup>AVE</sup>
            </td>
            <td />
            <td />
            <td />
          </tr>
        </template>
      </table>
    </div>
    <div class="table_wrap">
      <table border="0" cellpadding="0" cellspacing="0">
        <tr>
          <td width="12%" style="padding-top: 3px">
            Sub Scale:
          </td>
          <td width="12%" style="padding-top: 3px">
            Thread:
          </td>
          <td width="12%" style="padding-top: 3px">
            Seal:
          </td>
          <td width="40%" style="padding-top: 3px">
            <span v-html="getInspectionSheetDataForWeight('inspBoxFooter1', 0, false)" />
          </td>
          <td width="12%" style="padding-top: 3px">
            <table cellspacing="0" cellpadding="0" style="border: 0px solid white;">
              <tr>
                <td style="border: 0px solid white;">
                  <strong>SIZE</strong>
                </td>
                <td style="border: 0px solid white;">
                  <strong>L</strong>
                </td>
              </tr>
              <tr>
                <td style="border: 0px solid white;">
                  {{ size.size }}&quot;
                </td>
                <td style="border: 0px solid white;">
                  <span v-html="getInspectionSheetDataForWeight('inspBoxTrpd')" />
                </td>
              </tr>
            </table>
          </td>
          <td width="90">
            <img src="@/assets/images/gauge_sheet/L-diagram-box.png" alt="box diagram" width="89" height="51" style="margin-top: 2px">
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import AdminApi from '@/apis/AdminApi'

export default {
  name: 'BoxTemplate',
  props: {
    connection: {
      required: true,
      type: Object
    },
    applicationSelect: {
      type: String,
      default: 'box'
    },
    size: {
      required: true,
      type: Object
    },
    weight: {
      required: true,
      type: Object
    },
    measure: {
      type: String,
      required: true
    },
    bevel: {
      type: String,
      required: false
    },
    coupling: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      gaugeSheetText: [],
      decimalMetric: 2
    }
  },
  computed: {
    lionOrTiger () {
      if (this.connection.name.includes('JFELION') || this.connection.name.includes('JFETIGER')) {
        return true
      } else {
        return false
      }
    },
    uom () {
      if (this.measure === 'imp') {
        return '"'
      }
      return '<small>mm</small>'
    }
  },
  async mounted () {
    this.gaugeSheetText = await AdminApi.getInspectionSheetFields()
    this.loaded = true
  },
  methods: {
    getInspectionSheetDataForWeight (field, decimal = 4, sign = true) {
      try {
        const raField = field.substring(0, field.length - 2)
        let ltRange = 0
        let utRange = 0

        switch (field) {
          case 'inspShoulDepRa':
          case 'inspBoxThreadDiaRa':
          case 'inspBoxSealDiaRa':
          case 'inspBoxTaperPRa':
          case 'inspBoxThreadHtRa':
          case 'inspBoxBoreIdRa':
          case 'inspBoxNoseIdRa':
          case 'inspBoxCoupLenRa':
            // let ltRange = Math.abs(parseFloat(this.weight[raField + 'Nom'] || 0) - Math.abs(parseFloat(this.weight[raField + 'Lt'] || 0)))
            if (this.measure === 'imp') {
              ltRange = Math.abs(parseFloat(this.weight[raField + 'Nom'] || 0) + parseFloat(this.weight[raField + 'Lt'] || 0))
              utRange = Math.abs(parseFloat(this.weight[raField + 'Nom'] || 0) + parseFloat(this.weight[raField + 'Ut'] || 0))

              return (this.numberFormat(ltRange, decimal) || '') + ' - ' + (this.numberFormat(utRange, decimal) || '') + ((sign && ltRange && utRange) ? this.uom : '')
            } else {
              ltRange = Math.abs(parseFloat(this.numberFormat((this.weight[raField + 'Nom'] * 25.4), this.decimalMetric) || 0) + parseFloat(this.numberFormat((this.weight[raField + 'Lt'] * 25.4), this.decimalMetric) || 0))
              utRange = Math.abs(parseFloat(this.numberFormat((this.weight[raField + 'Nom'] * 25.4), this.decimalMetric) || 0) + parseFloat(this.numberFormat((this.weight[raField + 'Ut'] * 25.4), this.decimalMetric) || 0))

              return (this.numberFormat(ltRange, this.decimalMetric) || '') + ' - ' + (this.numberFormat(utRange, this.decimalMetric) || '') + ((sign && ltRange && utRange) ? this.uom : '')
            }
          case 'inspCoupOdRa':
            let ltRangeW = 0
            let utRangeW = 0
            // ltRangeW = Math.abs((this.getInspectionSheetDataForWeight(raField + 'Nom', decimal, false) || 0) - Math.abs(parseFloat(this.weight[raField + 'Lt'] || 0)))
            // utRangeW = Math.abs(parseFloat(this.getInspectionSheetDataForWeight(raField + 'Nom', decimal, false) || 0) + parseFloat(this.weight[raField + 'Ut'] || 0))
            if (this.measure === 'imp') {
              ltRangeW = Math.abs(parseFloat(this.getInspectionSheetDataForWeight(raField + 'Nom', decimal, false) || 0) + parseFloat(this.weight[raField + 'Lt'] || 0))
              utRangeW = Math.abs(parseFloat(this.getInspectionSheetDataForWeight(raField + 'Nom', decimal, false) || 0) + parseFloat(this.weight[raField + 'Ut'] || 0))

              return (this.numberFormat(ltRangeW, decimal) || '') + ' - ' + (this.numberFormat(utRangeW, decimal) || '') + ((sign && ltRangeW && utRangeW) ? this.uom : '')
            } else {
              ltRangeW = Math.abs(parseFloat(this.getInspectionSheetDataForWeight(raField + 'Nom', decimal, false) || 0) + parseFloat(this.numberFormat((this.weight[raField + 'Lt'] * 25.4), this.decimalMetric) || 0))
              utRangeW = Math.abs(parseFloat(this.getInspectionSheetDataForWeight(raField + 'Nom', decimal, false) || 0) + parseFloat(this.numberFormat((this.weight[raField + 'Ut'] * 25.4), this.decimalMetric) || 0))

              return (this.numberFormat(ltRangeW, this.decimalMetric) || '') + ' - ' + (this.numberFormat(utRangeW, this.decimalMetric) || '') + ((sign && ltRangeW && utRangeW) ? this.uom : '')
            }

          case 'inspBoxFooter1':
            return this.weight[field]

          case 'inspCoupOdNom':
            // field = 'dimTurnDiameterBox' + this.coupling.charAt(0).toUpperCase() + this.coupling.slice(1) + 'Cplg' + this.coupling + 'cOd'
            field = this.coupling ? this.coupling.cplgOd : null
            return this.treatValues(field, sign, decimal, true)
          case 'inspCoupMbFw':
            field = this.bevel && this.coupling ? (String(this.bevel) === String(20) ? this.coupling.mbFw20 : this.coupling.mbFw45) : null
            return this.treatValues(field, sign, decimal, true)
          default:
            return this.treatValues(field, sign, decimal)
        }
      } catch (e) {
        // Not found
        return e
      }
    },
    treatValues (field, sign, decimal, isCplg = false) {
      const fieldValue = isCplg ? field : this.weight[field]

      if (!fieldValue && fieldValue !== 0 && field !== 'inspCoupOdLt') {
        return null
      }

      if (this.measure === 'met' && !isNaN(fieldValue)) {
        // console.log("field=" + field)
        // if (field === 'inspCoupOdRa') {
        //   console.log('field=' + field)
        //   console.log('fieldValue * 25.4=' + (fieldValue * 25.4))
        //   console.log('metrical Treat Value=' + this.numberFormat(fieldValue * 25.4, this.decimalMetric, true))
        // }
        return this.numberFormat(fieldValue * 25.4, this.decimalMetric, true) + ((sign) ? this.uom : '')
      }

      return this.numberFormat(fieldValue, decimal, true) + ((sign) ? this.uom : '')
    },
    getInspectionSheetDataText (field, sign = true) {
      try {
        switch (field) {
          // case 'taper_p_ra':
          //     let specialRaField = field.substring(0, field.length - 2) //Take off the 'ra' final string
          //
          //     if(this.measure === 'imp'){
          //       return String( Math.abs( parseFloat(this.getInspectionSheetDBValue(specialRaField + 'nom')) - Math.abs( parseFloat(this.getInspectionSheetDBValue(specialRaField + 'lt') ) ) ) ) +
          //             ' - ' +
          //             String( Math.abs( parseFloat(this.getInspectionSheetDBValue(specialRaField + 'nom')) + parseFloat(this.getInspectionSheetDBValue(specialRaField + 'ut') ) ) ) +
          //             ((sign) ? this.uom : '')
          //     }else{
          //       return String( this.numberFormat(Math.abs( ( parseFloat(this.getInspectionSheetDBValue(specialRaField + 'nom') ) - Math.abs( parseFloat(this.getInspectionSheetDBValue(specialRaField + 'lt') ) ) ) * 25.4 ), 2) ) +
          //             ' - ' +
          //             String( this.numberFormat(Math.abs( (parseFloat(this.getInspectionSheetDBValue(specialRaField + 'nom') ) + parseFloat(this.getInspectionSheetDBValue(specialRaField + 'ut') ) ) * 25.4 ), 2) ) +
          //             ((sign) ? this.uom : '')
          //     }
          default:
            const fieldValue = this.getInspectionSheetDBValue(field)

            if (!fieldValue) {
              return '-'
            }

            if (this.measure === 'met' && !isNaN(fieldValue.replace)) {
              return this.numberFormat(fieldValue.replace * 25.4, this.decimalMetric) + ((sign) ? this.uom : '')
            }
            return fieldValue.replace + ((sign) ? this.uom : '')
        }
      } catch (e) {
        // Not found
        return e
      }
    },
    getInspectionSheetDBValue (field) {
      return this.gaugeSheetText.find(t => t.field === field && t.connectionId === this.connection.id)
    }
  }
}
</script>
